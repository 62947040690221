import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Filter Component -------------------------------------------------

const Filter = ({ filters, setFilters, count, displayMob }) => {
  //Handle dynamic filter change
  const handleFilter = (evt, filterProperty) => {
    const newFilter = { ...filters };
    newFilter[filterProperty] = evt.target.value;
    setFilters(newFilter);
  };

  //Handle page number drop-down, Dynmaic as per number of query results
  const pageSelector = (filters) => {
    let options = [];
    const pages =
      count <= filters.displayCount ? 1 : ~~(count / filters.displayCount) + 1;
    for (let i = 1; i <= pages; i++) {
      options.push(
        <option value={`${i}`} key={i}>
          {i}
        </option>
      );
    }
    return options;
  };

  return (
    <div className="filter" id={displayMob ? "" : "filter-hide"}>
      {/* Sorty by propery ie. product price or prduct name */}
      <div className="search-pan">
        <select
          onChange={(evt) => handleFilter(evt, "searchField")}
          name="searchField"
          id="searchField"
        >
          <option value="comics">COMICS</option>
          <option value="characters">PERSONNAGES</option>
        </select>
        <input
          className="search-bar"
          onChange={(evt) => handleFilter(evt, "searchStr")}
          type="text"
          name="title"
          id="title"
          placeholder="Rechercher par mots clés"
        />
      </div>

      {/* Number of results per page */}
      <div className="pages">
        <FontAwesomeIcon className="filter-icon" icon="th" />
        <select
          name="results-per-page"
          id="results-per-page"
          onChange={(evt) => handleFilter(evt, "displayCount")}
        >
          <option value="20">20</option>
          <option value="40">40</option>
          <option value="60">60</option>
          <option value="80">80</option>
          <option value="100">100</option>
        </select>
        <FontAwesomeIcon className="filter-icon" icon="forward" />
        <select
          name="page"
          id="page"
          onChange={(evt) => handleFilter(evt, "page")}
        >
          {pageSelector(filters)}
        </select>
      </div>
    </div>
  );
};

export default Filter;
