import { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Comic from "../components/comic";
import SEO from "../components/seo";
// import panimage from "../vinted-front2.jpg";

const Marvel = ({ favs, setFavs, token }) => {
  //Get id from params
  const { id } = useParams();

  //set initial states, comics, characters and query filters
  const [isLoading, setLoading] = useState(true);
  const [comics, setComics] = useState();

  //Get marvels data.. based on request and filter parameters..
  useEffect(() => {
    (async () => {
      // Get comics on page load..

      const response = await axios(
        `${process.env.REACT_APP_API}/character/${id}`
      );
      setComics(response.data.comics);
      setLoading(false);
    })();
  }, [id, favs]);

  //Helper Function...
  const favIcon = (favs, field, elem) => {
    const favArray = favs[field];
    if (favArray !== undefined && favArray.includes(elem._id)) {
      return true;
    }
    return false;
  };

  //Render the component.. on data load

  return isLoading ? (
    // Waiting data loading after async request...
    <div className='loading'>
      <img
        src='https://media.giphy.com/media/PUYgk3wpNk0WA/giphy.gif'
        alt='Loading'
      />
    </div>
  ) : (
    //For character request .. map and render character component
    <div>
      <SEO
        title='Marvel by Masood: Marvel'
        page='Marvel'
        description='Marvel project by Masood @ LE REACTEUR, PARIS'
        keywords={`Marvel, Le Reacteur, Paris`}
        robots={`index, follow`}
        link={`https://marvel.cloudi.fr/`}
        type='website'
        creator='Masood AHMAD'
        image='https://www.bdgrs.com/logo192.png'
      />
      <div className='main-pan container'>
        {comics.map((comic) => {
          return (
            <Comic
              key={comic._id}
              comic={comic}
              favs={favs}
              token={token}
              setFavs={setFavs}
              favIcon={favIcon}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Marvel;
