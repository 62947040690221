import { useState, useEffect } from 'react';
import axios from 'axios';
// import { Link, useParams, useLocation } from "react-router-dom";
import Filter from '../components/filter';
import Char from '../components/character';
import Comic from '../components/comic';
import SEO from '../components/seo';

// import panimage from "../vinted-front2.jpg";

const Home = ({ favs, setFavs, token, displayMob }) => {
  //set initial states, comics, characters and query filters

  const [isLoading, setLoading] = useState(true);
  const [count, setCount] = useState();
  const [marvels, setMarvels] = useState();
  const [filters, setFilters] = useState({
    searchField: 'comics',
    searchStr: '',
    displayCount: 100,
    page: 1,
  });

  //Get marvels data.. based on request and filter parameters..
  useEffect(() => {
    (async () => {

      
      // Get comics on page load..
      const { searchField, searchStr, displayCount, page } = filters;
      const response = await axios.get(
        `${process.env.REACT_APP_API}/comics?searchField=${searchField}&searchStr=${searchStr}&displayCount=${displayCount}&page=${page}`
      );

      setMarvels(response.data.results);
      setCount(response.data.count);

      if (token) {
        const favoris = await axios.get(
          `${process.env.REACT_APP_API}/user/getfavs`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          }
        );
        setFavs(favoris.data);
      }
      setLoading(false);
    })();
  }, [filters]);

  // Some helper Functions..
  //Which favourite icon to display
  const favIcon = (favs, field, elem) => {
    if (favs === null || favs === undefined) {
      return false;
    } else if (favs[field] && favs[field].indexOf(elem._id) !== -1) {
      return true;
    }
    return false;
  };

  // Component return .. .. COMIC or CHARACTER;;
  return isLoading ? (
    // Waiting data loading after async request...
    <div className='loading'>
      <img
        src='https://media.giphy.com/media/PUYgk3wpNk0WA/giphy.gif'
        alt='Loading'
      />
    </div>
  ) : filters.searchField === 'characters' ? (
    //For character request .. map and render character component
    <div className=''>
      <Filter
        filters={filters}
        setFilters={setFilters}
        count={count}
        displayMob={displayMob}
      />
      <img
        className='pan-image'
        src='https://static.wikia.nocookie.net/89c39e78-28f0-4ff9-bba0-ba562b26ff98'
        alt='Pan Image'
      />{' '}
      <div className='container main-pan'>
        {marvels.map((character) => {
          return (
            <Char
              key={character._id}
              character={character}
              favs={favs}
              token={token}
              setFavs={setFavs}
              favIcon={favIcon}
            />
          );
        })}
      </div>
    </div>
  ) : (
    //Otherwise .. map and render comics component..
    <div className=''>
      <SEO
        title='Marvel by Masood: Home'
        page='Home'
        description='Marvel project by Masood @ LE REACTEUR, PARIS'
        keywords={`Marvel, Le Reacteur, Paris`}
        robots={`index, follow`}
        link={`https://marvel.cloudi.fr/`}
        type='website'
        creator='Masood AHMAD'
        image='https://marvel.cloudi.fr/logo192.png'
      />
      <Filter
        filters={filters}
        setFilters={setFilters}
        count={count}
        displayMob={displayMob}
      />
      <img
        className='pan-image'
        src='https://static.wikia.nocookie.net/89c39e78-28f0-4ff9-bba0-ba562b26ff98'
        alt='Pani Image'
      />
      <div className='container main-pan'>
        {marvels.map((comic, index) => {
          return (
            <Comic
              key={comic._id}
              comic={comic}
              favs={favs}
              token={token}
              setFavs={setFavs}
              favIcon={favIcon}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Home;
