import "./App.css";
import Cookies from "js-cookie";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useState } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import Home from "./pages/home";
import Header from "./components/header";
import Login from "./pages/login";
import Signup from "./pages/signup";
import Footer from "./components/footer";
import Marvel from "./pages/marvel";
import Favo from "./pages/favourites";

import {
  faSignOutAlt,
  faSignInAlt,
  faSmileWink,
  faUserPlus,
  faHeart,
  faPlusCircle,
  faTh,
  faForward,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import Fav from "./pages/favourites";
library.add(
  faSignOutAlt,
  faSignInAlt,
  faSmileWink,
  faUserPlus,
  faHeart,
  faPlusCircle,
  faTh,
  faForward,
  faBars
);

function App() {
  // Set user (name) and token and favourites
  const [user, setUsername] = useState(Cookies.get("userName") || null);
  const [token, setToken] = useState(Cookies.get("Token") || null);
  const [favs, setFavs] = useState({ comics: [], persons: [] });
  const [displayMob, setDisplayMob] = useState(true);

  // User login handling function
  const setUser = (data) => {
    if (data.token) {
      Cookies.set("Token", data.token, { expires: 10 });
      Cookies.set("userName", data.account.username, { expires: 10 });
    } else {
      Cookies.remove("Token");
      Cookies.remove("userName");
    }
    setUsername(data.account.username);
    setToken(data.token);
    setFavs(data.favourites);
    console.log(favs);
    console.log(data.favourites);
  };

  //User logout handling
  const logout = () => {
    Cookies.remove("Token");
    Cookies.remove("userName");

    setToken(null);
    setUsername(null);
    setFavs({ comics: [], persons: [] });
  };

  return (
    <div>
      <Router>
        <Header
          user={user}
          logout={logout}
          displayMob={displayMob}
          setDisplayMob={setDisplayMob}
        />
        <Routes>
          <Route
            path="/"
            element={
              <Home
                setFavs={setFavs}
                favs={favs}
                token={token}
                displayMob={displayMob}
              />
            }
          />
          <Route
            path="/favourites"
            element={
              <Favo
                setFavs={setFavs}
                favs={favs}
                token={token}
                displayMob={displayMob}
              />
            }
          />
          <Route
            path="/character/:id"
            element={<Marvel favs={favs} setFavs={setFavs} token={token} />}
          />
          <Route path="/user/signup" element={<Signup setUser={setUser} />} />
          <Route path="/user/login" element={<Login setUser={setUser} />} />
        </Routes>
        <Footer></Footer>
      </Router>
    </div>
  );
}

export default App;
