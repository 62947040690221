const Footer = () => {
  return (
    <div className="footer">
      <p className="foot-notes">
        created by{" "}
        <a id="footer-link" href="mailto:masood@outlook.fr">
          Masood🤓
        </a>{" "}
        with react @{" "}
        <a id="footer-link2" href="https://www.lereacteur.io/" target="blank">
          le reacteur
        </a>
      </p>
    </div>
  );
};

export default Footer;
