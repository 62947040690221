import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";

const Comic = ({ comic, favs, setFavs, token, favIcon }) => {
  //Add to Favourite.. helper function
  const addFav = async () => {
    if (!token) {
      console.log(
        "No logged user found..Please log in before adding favourites"
      );
    } else {
      const newFavs = { ...favs };
      newFavs.comics.push(comic._id);
      const reqData = { token: token, favs: newFavs };
      const response = await axios.post(
        `${process.env.REACT_APP_API}/user/addfav`,
        reqData
      );

      setFavs(newFavs);
    }
  };

  //Remov from Favourite.. helper function
  const removeFav = async () => {
    if (!token) {
      console.log(
        "No logged user found..Please log in before adding favourites"
      );
    } else {
      console.log(favs);
      const newFavs = { ...favs };
      const toRemove = newFavs.comics.indexOf(comic._id);
      newFavs.comics.splice(toRemove, 1);
      const reqData = { token: token, favs: newFavs };
      const response = await axios.post(
        `${process.env.REACT_APP_API}/user/addfav`,
        reqData
      );
      console.log(response.data);
      setFavs(newFavs);
    }
  };

  return (
    <div className="comic-card">
      <div className="character-image">
        <img
          className="comic-img"
          src={`${comic.thumbnail.path}.${comic.thumbnail.extension}`}
          alt=""
        />
      </div>

      <div className="comic-labels">
        <div>
          <p className="comic-title">{comic.title}</p>
          <p className="comic-desc">{comic.description}</p>
        </div>
        <div className={token ? "fav-pan" : "fav-pan hide"}>
          <FontAwesomeIcon
            onClick={removeFav}
            className={favIcon(favs, "comics", comic) ? "" : "hide"}
            icon="heart"
          />
          <FontAwesomeIcon
            onClick={addFav}
            className={favIcon(favs, "comics", comic) ? "hide" : ""}
            icon="plus-circle"
          />
        </div>
      </div>
    </div>
  );
};

export default Comic;
