import { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Filter from '../components/filter';
import Char from '../components/character';
import Comic from '../components/comic';

// import panimage from "../vinted-front2.jpg";

const Favo = ({ favs, setFavs, token }) => {
  //set initial states, comics, characters and query filters
  const [isLoading, setLoading] = useState(true);
  const [count, setCount] = useState();
  const [marvels, setMarvels] = useState();
  const [filters, setFilters] = useState({
    searchField: 'comics',
    searchStr: '',
    displayCount: 100,
    page: 1,
  });

  //Get marvels data.. based on request and filter parameters..
  useEffect(() => {
    (async () => {
      // Get comics on page load..
      if (!token) {
        console.log(
          'No login token found. Please login to see your favourite marvels.'
        );
      } else {
         const { searchField, searchStr, displayCount, page } = filters;
        const response = await axios.get(
          `${process.env.REACT_APP_API}/comics?searchField=${searchField}&searchStr=${searchStr}&displayCount=${displayCount}&page=${page}`
        );
        setMarvels(response.data.results);
        setCount(response.data.count);
        setLoading(false);
      }
    })();
  }, [filters, favs]);

  // Some helper Functions..
  //Which favourite icon to display
  const favIcon = (favs, field, elem) => {
    if (favs === null || favs === undefined) {
      return false;
    } else if (favs[field] && favs[field].indexOf(elem._id) !== -1) {
      return true;
    }
    return false;
  };

  // Component return .. .. COMIC or CHARACTER;;
  return isLoading ? (
    // Waiting data loading after async request...
    <div className='loading'>
      <img
        src='https://media.giphy.com/media/PUYgk3wpNk0WA/giphy.gif'
        alt='Loading'
      />
    </div>
  ) : filters.searchField === 'characters' ? (
    //For character request .. map and render character component
    <div className=''>
      <Filter filters={filters} setFilters={setFilters} count={count} />
      <div className='container main-pan'>
        {marvels.map((character) => {
          if (favs.persons.length == 0 || favs.persons === undefined) {
            return;
          } else if (favs.persons.includes(character._id)) {
            return (
              <Char
                key={character._id}
                character={character}
                favs={favs}
                token={token}
                setFavs={setFavs}
                favIcon={favIcon}
              />
            );
          }
        })}
      </div>
    </div>
  ) : (
    //Otherwise .. map and render comics component..
    <div className=''>
      <Filter filters={filters} setFilters={setFilters} count={count} />
      <div className='container main-pan'>
        {marvels.map((comic, index) => {
          if (favs.comics.length == 0 || favs.comics === undefined) {
            return;
          } else if (favs.comics.includes(comic._id)) {
            return (
              <Comic
                key={comic._id}
                comic={comic}
                favs={favs}
                token={token}
                setFavs={setFavs}
                favIcon={favIcon}
              />
            );
          }
        })}
      </div>
    </div>
  );
};

export default Favo;
