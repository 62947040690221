import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import logo from "../logo.svg";

const Header = ({ user, logout, displayMob, setDisplayMob }) => {
  const navigate = useNavigate();

  //check if user is logged-in
  const handleFavourites = (evt) => {
    navigate("/", { state: { field: evt.target.value } });
  };

  return (
    <div className="header">
      <div className="nav">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
          <FontAwesomeIcon
            onClick={() => setDisplayMob(!displayMob)}
            className="burger"
            icon="bars"
          />
        </div>

        <ul className="nav-items">
          <li>
            <Link to="/" state={{ char: true }}>
              PERSONNAGES
            </Link>
          </li>
          <li>
            <Link to="/"> COMICS</Link>
          </li>
          <li>
            {user ? (
              <Link id="link" to={"/favourites"}>
                FAVORIS
              </Link>
            ) : (
              <span className="favoris">FAVORIS</span>
            )}
          </li>
        </ul>

        <div className="sign-in" id={displayMob ? "" : "login-hide"}>
          <div className="login-ins" id={user ? "signin-hide" : "signin"}>
            <Link to="/user/login">
              <span>SIGN IN | JOIN </span>
              <FontAwesomeIcon icon="sign-in-alt" />
            </Link>
          </div>

          <div className={`user ${user ? "" : "hide"}`}>
            <span>
              <FontAwesomeIcon icon="smile-wink" /> Salut,{" "}
              <span className="username">{user}</span>
            </span>
            <Link to="/">
              {" "}
              <FontAwesomeIcon
                className="user"
                onClick={logout}
                icon="sign-out-alt"
              />
            </Link>
          </div>
          <div className="fav-mobile">
            {user ? (
              <Link id="fav-mobile" to={"/favourites"}>
                <FontAwesomeIcon icon="heart" />
              </Link>
            ) : (
              <FontAwesomeIcon className="favoris" icon="heart" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
